import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
      Paper,
      List,
      ListItem
} from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

export default function SearchResults({ open, query }) {
      const [searchEmpty, setSearchEmpty] = useState(true);

      return (
            <Paper style={{
                  position: 'absolute',
                  right: '2rem',
                  top: '3.5rem',
                  width: '13rem',
                  opacity: open ? '1' : '0',
                  transition: 'opacity 250ms'
            }}>
                  <List style={{ color: '#555', padding: '1rem' }}>
                        <StaticQuery
                              query={graphql`
                                    {
                                          allContentfulSubject {
                                                edges {
                                                      node {
                                                            subject
                                                            fieldOne {
                                                                  internal{
                                                                        content
                                                                  }
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              `}
                              render={({ allContentfulSubject }) => {
                                    return allContentfulSubject.edges
                                          .filter(edge => new RegExp(query).test(edge.node.fieldOne.internal.content))
                                          .map(edge => {
                                                query === '' ? setSearchEmpty(true) : setSearchEmpty(false);

                                                if (!searchEmpty) {
                                                      const json = JSON.parse(edge.node.fieldOne.internal.content);
                                                      return (
                                                            <ListItem key={Math.random()}>
                                                                  {
                                                                        documentToReactComponents(json, {
                                                                              renderNode: {
                                                                                    [BLOCKS.HEADING_1]: (node, children) => <h3>{children}</h3>,
                                                                                    [BLOCKS.HEADING_2]: () => null,
                                                                                    [BLOCKS.HEADING_3]: () => null,
                                                                                    [BLOCKS.HEADING_4]: () => null,
                                                                                    [BLOCKS.PARAGRAPH]: () => null,
                                                                                    [BLOCKS.QUOTE]: () => null,
                                                                                    [BLOCKS.HR]: () => null,
                                                                                    [BLOCKS.UL_LIST]: () => null,
                                                                                    [BLOCKS.HR]: () => null,
                                                                              }
                                                                        })
                                                                  }
                                                            </ListItem>
                                                      );
                                                } else return null;
                                          })
                              }}
                        />
                  </List>
                  {
                        searchEmpty && <img src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/file_searching_duff.svg" alt="search" style={{ width: '100%', padding: '1.3rem' }} />
                  }
            </Paper>
      )
}