import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import Header from './header';
import Nav from './nav';
import Main from './main';

const theme = createMuiTheme({
      typography: {
            useNextVariants: true,
      },
});

const Layout = ({ classes, subject }) => {
      const [menuState, toggleMenu] = useState(false);

      return (
            <MuiThemeProvider theme={theme}>
                  <div className={classes.root}>
                        <Header menuState={menuState} toggleMenu={toggleMenu} />
                        <Nav menuState={menuState} toggleMenu={toggleMenu} />
                        <Main subject={subject} />
                  </div>
            </MuiThemeProvider>
      )
}

const styles = () => ({
      root: {
            display: 'flex',
      }
});

export default withStyles(styles)(Layout);