import React from 'react';
import { Link } from 'gatsby';
import { withStyles, Divider, Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalculator, faLandmark, faDesktop, faLanguage, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const NavList = ({ classes }) => {
      return (
            <>
                  <div className={classes.toolbar} />
                  <Divider />
                  <List>
                        <Typography variant="subtitle2" className={classes.drawerSubtitle}>Tantárgyak</Typography>
                        <Link to="/irodalom">
                              <ListItem button>
                                    <ListItemIcon><FontAwesomeIcon icon={faBook} /></ListItemIcon>
                                    <ListItemText>Irodalom & Nyelvtan</ListItemText>
                              </ListItem>
                        </Link>
                        <Link to="/matematika">
                              <ListItem button>
                                    <ListItemIcon><FontAwesomeIcon icon={faCalculator} /></ListItemIcon>
                                    <ListItemText>Matematika</ListItemText>
                              </ListItem>
                        </Link>
                        <Link to="/tortenelem">
                              <ListItem button>
                                    <ListItemIcon><FontAwesomeIcon icon={faLandmark} /></ListItemIcon>
                                    <ListItemText>Történelem</ListItemText>
                              </ListItem>
                        </Link>
                        <Link to="/angol">
                              <ListItem button>
                                    <ListItemIcon><FontAwesomeIcon icon={faLanguage} /></ListItemIcon>
                                    <ListItemText>Angol</ListItemText>
                              </ListItem>
                        </Link>
                        <Link to="/informatika">
                              <ListItem button>
                                    <ListItemIcon><FontAwesomeIcon icon={faDesktop} /></ListItemIcon>
                                    <ListItemText>Informatika</ListItemText>
                              </ListItem>
                        </Link>
                  </List>
                  <Divider />
                  <List>
                        <Typography variant="subtitle2" className={classes.drawerSubtitle}>Információ</Typography>
                        <ListItem button>
                              <ListItemIcon><FontAwesomeIcon icon={faCalendarAlt} /></ListItemIcon>
                              <ListItemText>Időpontok</ListItemText>
                        </ListItem>
                        <ListItem button>
                              <ListItemIcon><FontAwesomeIcon icon={faEnvelope} /></ListItemIcon>
                              <ListItemText>Elérhetőségek</ListItemText>
                        </ListItem>
                  </List>
            </>
      )
}

const styles = theme => ({
      toolbar: theme.mixins.toolbar,
      drawerSubtitle: {
            padding: '0.5rem 1rem',
      },
});

export default withStyles(styles)(NavList);