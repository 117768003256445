import React from 'react';
import { withStyles } from '@material-ui/core';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Main = ({ classes, subject }) => {
      return (
            <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <div>
                        <StaticQuery
                              query={graphql`
                                    {
                                          allContentfulSubject {
                                                edges {
                                                      node {
                                                            subject
                                                            topicOne
                                                            childContentfulSubjectFieldOneRichTextNode {
                                                                  json
                                                            }
                                                      }
                                                }
                                          }
                                    }
                              `}
                              render={
                                    ({ allContentfulSubject }) => {
                                          let nodesToRender = [];
                                          allContentfulSubject.edges.forEach(edge => {
                                                if (edge.node.subject === subject) {
                                                      nodesToRender.push(edge.node.childContentfulSubjectFieldOneRichTextNode.json)
                                                }
                                          })
                                          return nodesToRender.map(node => {
                                                return documentToReactComponents(node)
                                          })
                                    }
                              }
                        />
                  </div>
            </main>
      )
}

const styles = theme => ({
      content: {
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
      },
      toolbar: theme.mixins.toolbar,
})

export default withStyles(styles)(Main);