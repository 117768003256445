import React, { useState } from 'react';
import {
      AppBar,
      Toolbar,
      IconButton,
      Typography,
      InputBase,
      withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

import SearchResults from './search-results';

const Header = ({ classes, menuState, toggleMenu }) => {
      const [searchState, setSearchState] = useState(false);
      const [query, setQuery] = useState('');

      return (
            <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                        <IconButton className={classes.menuButton} onClick={() => toggleMenu(!menuState)} aria-label="Open drawer">
                              <MenuIcon />
                        </IconButton>
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                              ZKMSZ Érettségi Portál
                        </Typography>
                        <div className={classes.grow} />
                        <div className={classes.search}>
                              <div className={classes.searchIcon}>
                                    <SearchIcon />
                              </div>
                              <InputBase
                                    placeholder="Téma..."
                                    classes={{
                                          root: classes.inputRoot,
                                          input: classes.inputInput,
                                    }}
                                    onFocus={() => setSearchState(true)}
                                    onBlur={() => setSearchState(false)}
                                    onChange={(e) => setQuery(e.target.value)}
                              />
                        </div>
                        <SearchResults open={searchState} query={query} />
                  </Toolbar>
            </AppBar>
      )
}

const drawerWidth = 250;
const styles = theme => ({
      grow: {
            flexGrow: 1,
      },
      title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                  display: 'block',
            },
      },
      search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                  backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                  marginLeft: theme.spacing.unit,
                  width: 'auto',
            },
      },
      searchIcon: {
            width: theme.spacing.unit * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
      },
      inputRoot: {
            color: 'inherit',
            width: '100%',
      },
      inputInput: {
            paddingTop: theme.spacing.unit,
            paddingRight: theme.spacing.unit,
            paddingBottom: theme.spacing.unit,
            paddingLeft: theme.spacing.unit * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                  width: 120,
                  '&:focus': {
                        width: 200,
                  },
            },
      },
      appBar: {
            marginLeft: drawerWidth,
            [theme.breakpoints.up('sm')]: {
                  width: `calc(100% - ${drawerWidth}px)`,
            },
      },
      menuButton: {
            marginRight: 20,
            [theme.breakpoints.up('sm')]: {
                  display: 'none',
            },
      },
});

export default withStyles(styles)(Header);