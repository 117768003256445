import React from 'react';
import { withStyles } from '@material-ui/core';
import { Hidden, Drawer, SwipeableDrawer } from '@material-ui/core';

import NavList from './nav-list';

const Nav = ({ classes, menuState, toggleMenu }) => {
      return (
            <nav className={classes.drawer}>
                  <Hidden smUp implementation="css">
                        <SwipeableDrawer
                              variant="temporary"
                              open={menuState}
                              onOpen={() => toggleMenu(true)}
                              onClose={() => toggleMenu(false)}
                              classes={{
                                    paper: classes.drawerPaper,
                              }}
                        >
                              <NavList />
                        </SwipeableDrawer>
                  </Hidden>
                  <Hidden xsDown implementation="css">
                        <Drawer
                              classes={{
                                    paper: classes.drawerPaper,
                              }}
                              variant="permanent"
                              open
                        >
                              <NavList />
                        </Drawer>
                  </Hidden>
            </nav>
      )
}

const drawerWidth = 250;
const styles = theme => ({
      drawer: {
            [theme.breakpoints.up('sm')]: {
                  width: drawerWidth,
                  flexShrink: 0,
            },
      },
      drawerPaper: {
            width: drawerWidth,
      },
});

export default withStyles(styles)(Nav);